* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  /* outline-style: solid;
    outline-color: red;
    outline-width: 1px; */

  
}

body{
  background-color: #10101A;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.container {
    color: #FFFFFF;
  max-width: 1400px;
}

body::-webkit-scrollbar {
  display: none;
}


