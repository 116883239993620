@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.splashpage-container{
     width: 100%;
     margin-top: 150px;
     margin-bottom: 250px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     text-align: center;
}

.splashpage-heading{
    font-size: 80px;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-subheading{
    font-size: 40px;
    flex-basis: 75%;
    color: #848383;
    font-weight: 600;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-image-container{
    display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.splashpage-image{
      display: block;
  max-width:620px;
  width: auto;
  height: auto;
           opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-orange-object-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-blue-object-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-blue-obj{
    max-height: 120px;
    width: auto;
    animation-name: blue-obj-float;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    
}

.splashpage-orange-obj{
    max-height: 120px;
    justify-self: end;
    width: auto;
    animation-name: orange-obj-float;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes blue-obj-float{
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 25px); }
    100%   { transform: translate(0, -0px); }   
}

@keyframes orange-obj-float{
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, -25px); }
    100%   { transform: translate(0, -0px); }   
}

@media screen and (max-width: 1440px) {
  .splashpage-blue-obj{
    display: none;
    
}

.splashpage-orange-obj{
    display: none;
}
}

@media screen and (max-width: 670px) {
  .splashpage-image{
      display: block;
  max-width: 90vw;
  width: auto;
  height: auto;
           opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.splashpage-heading{
    font-size: 11vw;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}
.splashpage-subheading{
    font-size: 7vw;
    flex-basis: 75%;
    color: #848383;
    font-weight: 600;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}
}


