@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.navbar-container{
    width: 100%;
    display: grid;
    grid-template-columns: 0.45fr 6fr 2.0fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 40px;
}

.logo-container{
    display: flex;
    align-items: center;
    align-content: center;
}

.logo-image{
    width: 60px;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.navbar-links-container{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    align-content: center;
             opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

@media screen and (max-width: 1440px) {
.logo-image{
    margin-left: 30px;
}

.navbar-links-container{
    margin-right: 30px;
}


}

@media screen and (max-width: 1000px) {
.logo-image{
    margin-left: 30px;
}

.navbar-links-container{
    margin-right: 30px;
    justify-content: space-between;
}


}

@media screen and (max-width: 620px) {

    .navbar-container{
    width: 100%;
    display: grid;
    grid-template-columns: 0.45fr 3fr 3.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 40px;
}



}
