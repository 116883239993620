@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.project-container{
        width: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
     text-align: center;
     align-items: center;
     margin-bottom: 150px;
          opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out; 
}

.project-heading{
    font-size: 70px;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
}

.project-subheading{
    font-size: 35px;
    flex-basis: 75%;
    color: #848383;
    font-weight: 600;
}

.project-text{
    width: 75%;
    margin-bottom: 35px;
}

.project-image{
    max-width: 70%;
    height: auto;
    transition: all 0.3s;
}

.project-image:hover{
        transform: scale(1.01);
    transition: all 0.3s;
}

@media screen and (max-width: 670px) {

    .project-heading{
    font-size: 11vw;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
}

.project-subheading{
    font-size: 6vw;
    flex-basis: 75%;
    color: #848383;
    font-weight: 600;
}

.project-image{
    max-width: 90%;
    height: auto;
}

}