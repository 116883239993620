.card-container{
    margin-top: 80px;
    background-color: #1B1B2B;
    border-radius: 18px;
    transition: all 0.3s;
}

.card-container:hover{
    transform: scale(1.01);
    transition: all 0.3s;
}

.aboutme-card-content-container{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;

    flex-direction: column;
}

.aboutme-card-icon{
    margin-top: 36px;
    margin-bottom: 46px;
}

.aboutme-card-heading{
    font-size: 32px;
    margin-bottom: 35px;
    flex-basis: 100%;
    width: 80%;
}

.aboutme-card-explanation{
    width: 80%;
    font-size: 24px;
    color: #848383;
    margin-bottom: 35px;
    font-weight: 600;
}

.aboutme-card-skills-heading{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}

.aboutme-card-skills-item{
font-size: 24px;
font-weight: 600;
margin-bottom: 15px;
color: #848383;
}