@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.aboutme-container{
     width: 100%;
     margin-bottom: 200px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     text-align: center;

     opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out; 
}

.hidden{
    visibility: hidden;
}

.aboutme-heading{
    font-size: 70px;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
                  opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.aboutme-subheading{
    font-size: 35px;
    flex-basis: 75%;
    color: #848383;
    font-weight: 600;
                  opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.aboutme-cards-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
}

.aboutme-learn-more-container{
    width: 100%;
    margin-top: 60px;
    background-color: #1B1B2B;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutme-learn-more-content{
    margin: 30px 20px 30px 20px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;

}

.card-animation{
                      opacity: 0;
    animation: fadeInUp 1s forwards ease-in-out;
}

.card-animation-middle{
                      opacity: 0;
    animation: fadeInUp 1.3s forwards ease-in-out;
}

.aboutme-learn-more-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutme-learn-more-heading-text{
    font-size: 36px;
}

.aboutme-download-resume-button{
    text-decoration: none ;
    width: 80%;
    color: white;
    outline: 1px solid white;
    border-radius: 8px;
        transition: all 0.3s;
}

.aboutme-download-resume-button-text{
    padding: 10px 10px 10px 10px;
    font-weight: 600;
    font-size: 28px;
    transition: all 0.3s;
}

.aboutme-download-resume-button:hover{
    background-color: white;
    transition: all 0.3s;
}

.aboutme-download-resume-button:hover h1{
    color: #1B1B2A;
    transition: all 0.3s;
}

@media screen and (max-width: 1440px) {
.aboutme-cards-container{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 0.5fr);
grid-template-rows: 0.5fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
}
.aboutme-learn-more-container{
    width: 50%;
    margin-top: 60px;
    background-color: #1B1B2B;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.aboutme-learn-more-content{
    margin: 30px 20px 30px 20px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 30px;

}
}

@media screen and (max-width: 670px) {

.aboutme-heading{
    font-size: 11vw;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
}

.aboutme-subheading{
    font-size: 6vw;
    width: 90%;
    align-self: center;
    color: #848383;
    font-weight: 600;
}

.aboutme-cards-container{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 0.9fr);
grid-template-rows: 0.5fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
}

.aboutme-learn-more-container{
    width: 90%;
    margin-top: 60px;
    background-color: #1B1B2B;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}
}

@media screen and (max-width: 1000px) {
.aboutme-learn-more-container{
    width: 90%;
    margin-top: 60px;
    background-color: #1B1B2B;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.aboutme-cards-container{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 0.9fr);
grid-template-rows: 0.5fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
}


}
