.footer-container{
         width: 100%;
         margin-bottom: 100px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     text-align: center;
     align-items: center;
}

.footer-inner-container{
    width: 50%;
    border-top: 1px solid rgba(255,255,255,0.5); 
    display: grid;
grid-template-columns: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
}

.footer-inner-inner-container{
    margin-top: 80px;
}

.footer-logo{
    max-width: 120px;
    height: auto;
}

.footer-links-container-outer{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 50px;
}

.footer-links-container{
    margin-top: 50px;
        width: 40%;
    display: grid;
        grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-row-gap: 0px;
justify-items: center;
}

.footer-link{
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 70px;
    height: 70px;
    transition: all 0.2s;
}

.footer-link:hover{
    background-color: white;
    transition: all 0.2s;
}

.footer-phrase{
    margin-top: 10px;
    font-weight: 400;
}


@media screen and (max-width: 1000px) {
.footer-links-container{
    width: 100%;
}


}


